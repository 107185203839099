<template>
    <div>
        <el-tooltip v-if="matchLevel === 100" class="item" effect="light" content="Matched" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-green-500" />
        </el-tooltip>
        <el-tooltip v-if="hasDebt" class="item" effect="light" content="Skuld till Bolageriet" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-black" />
        </el-tooltip>
        <el-tooltip v-if="isFactoring" class="item" effect="light" content="Factoring" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-blue-500" />
        </el-tooltip>
        <el-tooltip v-if="matchLevel === 80" class="item" effect="light" content="OCR matched, amount > rest" placement="right">
            <div class="flex">
                <p class="flex h-4 w-2 rounded-l-full bg-green-500" />
                <p class="flex h-4 w-2 rounded-r-full bg-yellow-500" />
            </div>
        </el-tooltip>
        <el-tooltip v-if="matchLevel === 60" class="item" effect="light" content="OCR matched, amount < rest" placement="right">
            <div class="flex">
                <p class="flex h-4 w-2 rounded-l-full bg-yellow-500" />
                <p class="flex h-4 w-2 rounded-r-full bg-green-500" />
            </div>
        </el-tooltip>
        <el-tooltip v-if="matchLevel === 40" class="item" effect="light" content="OCR missing, invoice number matched" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-yellow-500" />
        </el-tooltip>
        <el-tooltip v-if="matchLevel === 20" class="item" effect="light" content="Negativa belopp i betalningen" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-red-500" />
        </el-tooltip>
        <el-tooltip v-if="matchLevel === 0" class="item" effect="light" content="Unmatched" placement="right">
            <span class="flex h-4 w-4 rounded-full bg-gray-300" />
        </el-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        matchLevel: {
            type: Number,
            default: 0,
        },
        hasDebt: {
            type: Boolean,
            default: false,
        },
        isFactoring: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        selectType(type) {
            this.$emit("select", type);
        },
    },
};
</script>
